import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as Actions from "../../redux/actions";
import { langData } from "../../index.i18n";
import { Link } from "react-router-dom";
import Loader from "../../components/Loader";
// import Cookies from "js-cookie";
import { _currencyFormatter } from "../../helpers";
import { Modal } from "react-bootstrap";
import axios from "axios";
import { FETCH_URL } from "../../constants";

export class Cart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };
  }
  componentDidMount() {
    const { userToken } = this.props;
    if (!userToken) {
      this.props.history.push({
        pathname: "/login/1",
      });
    } else {
      this.props.fetchCartData(userToken, null);
        this.props.fetchUserData(userToken);
    }
    window.scrollTo(0, 0);
  }

  getTotalPrice = () => {
    const { CartData } = this.props;
    let totalPrice = CartData.length
      ? CartData.map((a) => a.standart.price).reduce((a, b) => a + b)
      : 0;
    return _currencyFormatter(totalPrice);
  };
  bookOrders = () => {
    const config = {
      headers: { Authorization: `Bearer ${this.props.userToken}` },
    };
    axios
      .post(`${FETCH_URL}booking`, null, config)
      .then((response) => {
        if (response.data.success) {
          this.props.history.push("/orders");
        } else {
          alert("fail");
        }
      })
      .catch((err) => alert(JSON.stringify(err.message)));
  };
  render(){
    const { mlang, CartData, LoadingCartData, userToken, userData } = this.props;
      console.log(userData);
      console.log(this.props);
      console.log(this.props.fetchUserData(userToken));
    const { show } = this.state;
    const data =
      mlang === "uz" ? langData.uz : mlang === "ru" ? langData.ru : langData.en;
    // console.log(
    //   "CartData: ",
    //   CartData.data ? CartData.data.map((a) => a.id) : null
    // );
    return LoadingCartData ? (
      <Loader />
    ) : (
      <div>
        <div className="full_page">
          <section className="auth basket">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <h1>{data.basketTitle}</h1>
                  <table>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th colSpan={3}>{data.basketProductName}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {CartData &&
                        CartData.map((cardItem, index) => (
                          <tr>
                            <th>{index + 1}</th>
                            <th>
                              <Link to={`/shop/${cardItem.standart.id}`}>
                                {/* {_translator(
                                "name_standart",
                                cardItem.standart,
                                mlang
                              )} */}
                                {cardItem.standart.name_standart}
                              </Link>
                            </th>
                            <th>
                              {_currencyFormatter(cardItem.standart.price)}
                            </th>
                            <th>
                              <button
                                onClick={() => {
                                  this.props.setLoader("LoadingCartData");
                                  this.props.fetchCartData(
                                    userToken,
                                    cardItem.id
                                  );
                                }}
                              >
                                ×
                              </button>
                            </th>
                          </tr>
                        ))}
                    </tbody>
                  </table>
<a 
  href={`https://t.me/certificationuz_bot?start=${CartData[0].user_id}`} 
  target="_blank" 
  rel="noopener noreferrer"
  className="know_more"
  style={{ display: "block", margin: "10px 0" }}
>
  Связаться с ботом
</a>	
                  <div className="basket__total">
                    <span>{data.basketTotalPrice}:</span>
                    <b> {this.getTotalPrice()}</b>
                  </div>
                  <div className="basket__buttons">
                    <Link to="/shop" className="know_more">
                      {data.basketBackButton}
                    </Link>
                    <div
                      style={{
                        display: CartData.length ? "block" : "none",
                        cursor: "pointer",
                        width: "200px",
                        textAlign: "center",
                        marginBottom: "5px",
                      }}
                      onClick={() => this.setState({ show: true })}
                      // to={!userToken ? "/login/1" : "/orders"}
                      className="know_more"
                    >
                      {data.basketOrderButton}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <Modal
          show={show}
          onHide={() => this.setState({ show: false })}
          // size=""
          dialogClassName="modal-90h"
        >
          <Modal.Header closeButton>
            <Modal.Title>{data.basketModalTitle} </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* <iframe
              src={`https://smsiti.smarttechnology.uz/storage/${JournalDetailsData.fileHash}?page=hsn#toolbar=0`}
              title="title"
              style={{ height: window.innerHeight * 0.65, width: "100%" }}
            /> */}
            {/* <Modap pdfProps={url} /> */}
            <div
              // className="offset-2 col-md-9"
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <Link
                onClick={() => this.setState({ show: false })}
                className="know_more"
                style={{ background: "red" }}
              >
                {data.basketModalCancel}
              </Link>
              <Link
                onClick={() => {
                  this.bookOrders();
                  this.setState({ show: false });
                }}
                // to={!userToken ? "/login/1" : "/orders"}
                className="know_more"
              >
                {data.basketOrderButton}
              </Link>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    mlang: state.languageReducer.language,
    CartData: state.fetchDataReducer.CartData,
    userToken: state.fetchDataReducer.userToken,
    LoadingCartData: state.fetchDataReducer.LoadingCartData,
    userData: state.fetchDataReducer.userData,
  };
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators(Actions, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(Cart);
